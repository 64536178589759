import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import {
  Layout,
  SEO,
  Hero,
  HeadingWithCopy,
  Banner,
  Contact,
} from '../components';
import { useGraphQL } from '../hooks';

function CompanyProfilePage() {
  const { banner1 } = useStaticQuery(graphql`
    {
      banner1: file(relativePath: { eq: "company-profile-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Company Profile" />
      <HomeHero />
      <ProfilePartOne />
      <Banner
        image={banner1.childImageSharp.fluid}
        imgStyle={{ objectPosition: 'top' }}
      />
      <ProfilePartTwo />
      <Contact bgClassName="bg-grey" />
    </Layout>
  );
}

function ProfilePartOne() {
  return (
    <HeadingWithCopy heading="Our Culture Statement">
      <ul>
        <li>
          Keep things simple and real, whilst thinking big and building on
          genuine successes through innovation
        </li>
        <li>
          Encourage staff and clients to develop trust in us based on our
          knowledge and reliability, whilst continuing to expand our knowledge
          and maintain our reliability
        </li>
        <li>Quality over quantity</li>
        <li>
          Demonstrate genuine interest and investment in our clients and staff
          through open communication
        </li>
        <li>
          Maintain a calm, inclusive, enjoyable, and well-run work environment
          for our carefully chosen team of staff and contractors. Their safety
          and future are in our hands.
        </li>
      </ul>
    </HeadingWithCopy>
  );
}

function ProfilePartTwo() {
  return (
    <HeadingWithCopy
      heading="Specialists in Aviation for over 50 years"
      cta={{
        slug: '/contact-us/',
        label: 'Want to know more? Get in touch',
      }}
    >
      <h2>Our Story</h2>
      <p>
        Starting out as a family-owned business over 50 years ago, R&M Aviation
        began by providing agricultural aerial services across Australia’s east
        coast. In 1995, as the country’s worsening bushfire crisis saw the
        demand for firefighting aircraft grow, the company moved into the
        critical field of aerial firefighting.
      </p>
      <p>
        Since then, R&M Aviation has proudly served at the forefront of every
        fire season in Australia, helping to safeguard communities, support our
        partners, and protect the land and environment.
      </p>
      <p>
        Today, with a team that includes some of the most accomplished
        firefighting pilots in the world, R&M Aviation has gone on to establish
        itself as a leading name in aerial firefighting, with a global
        client-base that includes government agencies and forestry companies. In
        addition, our dust suppression services are utilised by mining operators
        to reduce their environmental impact.
      </p>
      <p>
        While R&M Aviation has experienced significant growth since its
        inception, the values that stem from being a family-owned company have
        remained. This means we are committed to providing exceptional customer
        service and forming strong, supportive relationships with those we
        partner with, and we continue to pride ourselves on extensive piloting
        experience and our dedication to aviation excellence.
      </p>
      <p>
        Now, as we look ahead to a future in which the impact of climate change
        will undoubtedly see a rising demand for aerial firefighting, we are
        determined to continue upholding our vision of delivering aviation
        excellence in the protection of land, nature, and people.
      </p>
    </HeadingWithCopy>
  );
}

function HomeHero() {
  const { companyProfileHero } = useGraphQL();

  return (
    <Hero image={companyProfileHero.childImageSharp.fluid}>
      <h1 className="absolute inset-x-0 w-full max-w-4xl px-4 mx-auto text-4xl font-bold tracking-widest text-center uppercase md:text-6xl sm:px-6 lg:px-8 bottom-12 xl:bottom-64 text-yellow">
        Company profile
      </h1>
    </Hero>
  );
}

export default CompanyProfilePage;
